import React, { useMemo } from 'react';
import { MapContainer, MapContainerProps, TileLayer } from 'react-leaflet';
import { LeafletEventHandlerFnMap as LeafletMapEvents, Map as LeafletMap } from 'leaflet';
import InternalMapEvents from './Internal/MapEvents';
import InternalMapRef from './Internal/MapRef';
import appConfig from 'appConfig';

export interface MapProps extends MapContainerProps {
  flex?: boolean;
  events?: LeafletMapEvents;
}

export interface MapRefProps {
  map: LeafletMap;
}

const Map = React.forwardRef<MapRefProps, MapProps>(({ children, flex = true, style, events, ...props }: MapProps, ref) => {
  const mergedStyle = useMemo(() => ({ ...style, flex: flex ? 1 : undefined }), [ style, flex ]);

  return (
    <MapContainer {...props} minZoom={1} maxZoom={22} style={mergedStyle}>
      {children}
      <InternalMapEvents events={events} />
      <InternalMapRef ref={ref} />
      <TileLayer attribution={'&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'} url={appConfig.screenshotMode ? 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png' : 'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'} noWrap />
    </MapContainer>
  );
});

export type { LeafletMap, LeafletMapEvents };

export default Map;
