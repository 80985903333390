import React from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet/types/Marker';

import { EventMarkerPopup } from 'Components';
import { RoadTrip } from 'models';
import appConfig from 'appConfig';

interface Props extends MarkerProps {
  roadTrip: RoadTrip;
  isLast: boolean;
}

const defaultIcon = L.divIcon({
  className: 'my-custom-pin',
  iconAnchor: [ 15, 24 ],
  // labelAnchor: [ -6, 0 ],
  popupAnchor: [ 0, -24 ],
  html: '<span class="artifact-marker" />',
});

const lastIcon = L.divIcon({
  className: 'my-custom-pin',
  iconAnchor: [ 15, 24 ],
  // labelAnchor: [ -6, 0 ],
  popupAnchor: [ 0, -24 ],
  html: '<span class="artifact-marker last" />',
});

const EventMarker = ({ roadTrip, isLast, ...props }: Props) => {
  const resolvedLastIcon = appConfig.screenshotMode ? defaultIcon : lastIcon;
  return (
    <Marker zIndexOffset={isLast ? 5000 : 0} icon={isLast ? resolvedLastIcon : defaultIcon} {...props}>
      {roadTrip && <EventMarkerPopup roadTrip={roadTrip} />}
    </Marker>
  );
};

export default EventMarker;
