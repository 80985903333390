export interface SiteConfig {
  baseUrl: string;
  screenshotMode: boolean;
}

const appConfig: SiteConfig = {
  // baseUrl: 'https://localhost:44357',
  baseUrl: 'https://scottrupprecht.azurewebsites.net',
  screenshotMode: false,
};

export default appConfig;
