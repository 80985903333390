import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Container } from 'reactstrap';
import axios from 'axios';

import appConfig from 'appConfig';
import { RoadTrip } from 'models';
import { EventMarker, EventsMap, MapFlexContainer, Panel } from 'Components';

const KM_TO_MILES = 0.621371;

const EventsPage = () => {
  const [ roadTrips, setRoadTrips ] = useState<RoadTrip[]>([]);

  useEffect(() => {
    searchEvents();
  }, []);

  function searchEvents() {
    axios.get<RoadTrip[]>(`${appConfig.baseUrl}/api/roadTrips`)
      .then(({ data: roadTrips }) => {
        setRoadTrips(roadTrips);
      });
  }

  const totalDistanceInKm = useMemo(() => {
    let totalDistanceInKm = 0;

    for (let i = 0; i < roadTrips.length; i++) {
      const previous: RoadTrip | undefined = roadTrips[i - 1];
      const current = roadTrips[i];
      if(!previous) {
        continue;
      }

      totalDistanceInKm += getDistanceFromLatLonInKm(previous.latitude, previous.longitude, current.latitude, current.longitude);
    }

    return totalDistanceInKm;
  }, [ roadTrips ]);

  const totalDistanceInMiles = totalDistanceInKm * KM_TO_MILES;

  console.log({ totalDistanceInMiles });

  function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI/180);
  }

  return (
    <Container fluid tag={Panel} className="py-2" childSpacingY={2}>
      <Panel>
        <div>
          <h4>{totalDistanceInMiles.toFixed(2)} Miles</h4>
        </div>
        <MapFlexContainer>
          <EventsMap
            zoom={4}
            center={{ lat: 39.8281, lng: -98.5795 }}>
            {
              _.map(roadTrips, (roadTrip, index) => {
                const isLast = index === roadTrips.length - 1;
                return (
                  <EventMarker
                    key={roadTrip.id}
                    roadTrip={roadTrip}
                    isLast={isLast}
                    position={{ lat: roadTrip.latitude, lng: roadTrip.longitude }}
                  />
                );
              })
            }
          </EventsMap>
        </MapFlexContainer>
      </Panel>
    </Container>
  );
};

export default EventsPage;
