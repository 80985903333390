import React from 'react';
import { format, parseISO, addHours } from 'date-fns';
import { Popup } from 'react-leaflet';
import { RoadTrip } from 'models';

interface Props {
  roadTrip: RoadTrip;
}

const EventMarkerPopup = ({ roadTrip, ...props }: Props) => {
  return (
    <Popup autoPan={false}>
      <div>
        <h5 className="child-spacing-x-1">{format(addHours(parseISO(roadTrip.createdTimestamp), 6), 'yyyy-MM-dd hh:mm:ss aa')}</h5>
        <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/maps?q=${roadTrip.latitude},${roadTrip.longitude}`}><h5 className="child-spacing-x-1">View on Google Maps</h5></a>
      </div>
    </Popup>
  );
};

export default EventMarkerPopup;
